<template>
  <div>我是dataStatistics</div>
</template>
<script>
export default {
  name: "dataStatistics",
  data() {
    return {};
  }
};
</script>
